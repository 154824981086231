<template>
    <LiefengContent>
        <template #title>{{ menuCodeObj.menuName || '栏目统计情况'}}</template>
        <template #toolsbarRight>
            <Form :label-colon="true" :inline="true" class="search">
                <FormItem>
                    <Select transfer v-model="orgCodeList[0]" placeholder="选择区" style="width: 200px; margin-right: 5px" @on-change="changeArea">
                        <Option v-for="item in areaList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select transfer clearable v-model="orgCodeList[1]" placeholder="选择街道" style="width: 200px; margin-right: 5px" @on-change="changeStreet">
                        <Option v-for="item in streetList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select transfer clearable v-model="orgCodeList[2]" placeholder="选择社区" style="width: 200px; margin-right: 5px" @on-change="changeCommunity">
                        <Option v-for="item in communityList" :value="item.value" :key="item.value">{{ item.label }}</Option>
                    </Select>
                </FormItem>
                <Button type="primary" style="margin-right: 10px" @click="search">查询</Button>
                <Button type="error" style="margin-right: 10px" @click="Export">导出</Button>
                <Button type="primary" style="margin-right: 10px" @click="install">非栏目功能设置</Button>
            </Form>
        </template>
        <template #contentArea>
            <LiefengTable :tableData="tableData" :talbeColumns="talbeColumns" :loading="loading" :fixTable="true" :hidePage="true"></LiefengTable>
            <!-- 栏目功能设置 -->
            <LiefengModal title="栏目功能设置" width="600px" height="70vh" :value="installStatus" @input="changeInstallStatus" :fullscreen="true">
                <template v-slot:contentarea>
                    <Install ref="install" @getList="getIList"></Install>
                </template>
                <template v-slot:toolsbar>
                    <Button style="margin: 0 5px 0 13px" type="primary" :loading="saveLoading" @click="saveInstall">保存</Button>
                </template>
            </LiefengModal>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import LiefengModal from "@/components/LiefengModal"
import Install from "./children/install"
export default {
    data() {
        return {
            installStatus: false,
            installList: [],
            areaList: [],
            areaCode: "",
            streetCode: "",
            streetList: [],
            communityCode: "",
            communityList: [],

            //菜单树社区编码
            orgCode: [],
            orgCodeList: [],

            tableData: [],
            talbeColumns: [
                {
                    title: "导航分类",
                    key: "layerRoot",
                    align: "center",
                    minWidth: 160,
                },
                {
                    title: "一级栏目",
                    key: "layerFirst",
                    align: "center",
                    minWidth: 160,
                },
                {
                    title: "二级栏目",
                    key: "layerSecond",
                    align: "center",
                    minWidth: 160,
                },
                {
                    title: "三级栏目",
                    key: "layerThird",
                    align: "center",
                    minWidth: 160,
                },
                {
                    title: "四级栏目",
                    key: "layerFourth",
                    align: "center",
                    minWidth: 160,
                },
                {
                    title: "五级栏目",
                    key: "layerFifth",
                    align: "center",
                    minWidth: 160,
                },
                {
                    title: "栏目最新更新日期",
                    key: "lastGmtModified",
                    align: "center",
                    minWidth: 160,
                },
                {
                    title: "几天未更新了",
                    key: "notUpdateTime",
                    align: "center",
                    minWidth: 160,
                },

                {
                    title: "信息发布的总数(市、区、街道、社区)",
                    key: "dataInfo",
                    align: "center",
                    minWidth: 160,
                },
                {
                    title: "信息发布的总数(社区)",
                    key: "communityInfo",
                    align: "center",
                    minWidth: 160,
                },
                {
                    title: "阅读总数",
                    key: "communityPv",
                    align: "center",
                    minWidth: 160,
                },
                {
                    title: "阅读人数",
                    key: "communityUv",
                    align: "center",
                    minWidth: 160,
                },
                {
                    title: "参与人数",
                    key: "communityParticipator",
                    align: "center",
                    minWidth: 160,
                },
            ],
            loading: false,
            showNum: 1,
            menuCodeObj:{}
        }
    },
    async created() {
        await this.$get("/old/api/pc/menu/selectMenuByMenuCode", {
          orgCode: parent.vue.loginInfo.userinfo.orgCode,
          menuCode: this.$core.getUrlParam("menuId")
        }).then(res => {
          if (res.code == 200 && res.data) {
            this.menuCodeObj = res.data;
          }
        });
        await this.getAreaList("4401")
        await this.getStreetList(this.orgCodeList[0])
        await this.getCommunityList(this.orgCodeList[1])
        this.getList(this.orgCodeList[2])
    },
    methods: {
        changeArea(val) {
            console.log(val)
            this.getStreetList(val)
        },
        changeStreet(val) {
            console.log(val)
            if (!val) {
                this.orgCodeList[2] = ""
                this.communityList = []

                return
            }
            this.getCommunityList(val)
        },
        async getAreaList(orgCode) {
            let res = await this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            })
            if (res.code == 200 && res.dataList.length != 0) {
                this.areaList = res.dataList.map(item => {
                    return {
                        value: item.orgCode,
                        label: item.orgName,
                    }
                })
                this.orgCodeList[0] = this.areaList[0].value
            }
        },

        async getStreetList(orgCode) {
            let res = await this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            })
            if (res.code == 200 && res.dataList.length != 0) {
                this.streetList = res.dataList.map(item => {
                    return {
                        value: item.orgCode,
                        label: item.orgName,
                    }
                })
                this.orgCodeList[1] = this.streetList[0].value
            }
        },

        async getCommunityList(orgCode) {
            let res = await this.$get("/orgzz/pc/organization/selectCascadeDataScope", {
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode,
                orgCodeSelf: parent.vue.loginInfo.userinfo.orgCode,
            })
            if (res.code == 200 && res.dataList.length != 0) {
                this.communityList = res.dataList.map(item => {
                    return {
                        value: item.orgCode,
                        label: item.orgName,
                    }
                })
                if (this.showNum == 1) this.orgCodeList[2] = this.communityList[0].value
                this.showNum++
            }
        },
        search() {
            console.log(this.orgCodeList)
            if (!this.orgCodeList[1] && !this.orgCodeList[2]) {
                this.$Message.warning({
                    content: "请选择到街道或社区再进行查询",
                    background: true,
                })
                return
            }
            let code = ""
            if (this.orgCodeList[2]) {
                code = this.orgCodeList[2]
                
                this.talbeColumns[6].key = "lastGmtModified"
                this.talbeColumns[7].key = "notUpdateTime"
                this.talbeColumns[8].key = "dataInfo"
                this.talbeColumns[9].key = "communityInfo"
                this.talbeColumns[9].title = "信息发布的总数(社区)"
                this.talbeColumns[10].key = "communityPv"
                this.talbeColumns[11].key = "communityUv"
                this.talbeColumns[12].key = "communityParticipator"
            } else if (this.orgCodeList[1]) {
                code = this.orgCodeList[1]

                this.talbeColumns[6].key = "lastGmtModified"
                this.talbeColumns[7].key = "notUpdateTime"
                this.talbeColumns[8].key = "dataInfo"
                this.talbeColumns[9].key = "streetInfo"
                this.talbeColumns[9].title = "信息发布的总数(街道)"
                this.talbeColumns[10].key = "streetPv"
                this.talbeColumns[11].key = "streetUv"
                this.talbeColumns[12].key = "streetParticipator"
            }
            this.getList(code)
        },
        //获取列表
        getList(orgCode) {
            this.loading = true
            this.$get("/statistic/api/symanage/pc/statColumn/selectOrgColumnPvList", {
                orgCode,
                statLayer: 5,
            }).then(res => {
                if (res.code == 200) {
                    this.loading = false
                    this.tableData = res.dataList
                    // this.page = res.maxPage
                } else {
                    this.loading = false
                    this.$Message.error({
                        content: "获取详情失败",
                        background: true,
                    })
                }
            })
        },
        //导出
        Export() {
            let code = ""
            if (this.orgCodeList[2]) {
                code = this.orgCodeList[2]
            } else if (this.orgCodeList[1]) {
                code = this.orgCodeList[1]
            } else {
                code = this.orgCodeList[0]
            }
            console.log(code)
            this.$Modal.confirm({
                title: "温馨提示",
                loading: true,
                content: "是否导出栏目阅读数统计",
                onOk: () => {
                    this.$get(
                        "/statistic/api/symanage/pc/statColumn/expExcelStatColumnPvUrl",
                        {
                            orgCode: code,
                        },
                        { "Content-Type": "application/json" }
                    ).then(res => {
                        if (res.code == 200) {
                            this.$Modal.remove()
                            if (!res.data) {
                                this.$Message.warning({
                                    content: "暂无数据导出",
                                    background: true,
                                })
                                return
                            }
                            if (res.data) {
                                if (res.data.slice(0, 4) == "http" && res.data.slice(0, 5) !== "https") {
                                    res.data = res.data.replace("http", "https")
                                }
                            }

                            let link = document.createElement("a")
                            link.href = res.data
                            document.body.appendChild(link)
                            link.click()
                            link.download = "导出信息"
                            document.body.removeChild(link)
                            this.$Message.destroy()
                        } else {
                            this.$Modal.remove()
                            this.$Message.error({
                                content: "导出失败",
                                background: true,
                            })
                        }
                    })
                },
            })
        },

        install() {
            this.installStatus = true
            this.$refs.install.getList()
        },
        changeInstallStatus(val) {
            this.installStatus = val
        },
        getIList(val) {
            this.installList = val
        },
        saveInstall() {
            let arr = []
            arr = this.installList.filter(item => !item.id)
            if(!arr.length){
                this.installStatus = false
            }else{
                if (!(arr[0].name && arr[0].action)) {
                    this.$Message.warning({
                        content: "请补充完整",
                        background: true,
                    })
                    return
                }
                this.$post("/gateway/apps/basic/api/base/finger/element/addAppColumnBlackList", arr, { "content-type": "application/json" }).then(res => {
                    if (res.code == 200) {
                        this.$Message.success({
                            content: "新增成功",
                            background: true,
                        })
                        this.installStatus = false
                        this.getList(this.orgCodeList[2])
                    } else {
                        this.$Message.error({
                            content: "新增失败",
                            background: true,
                        })
                    }
                })
            }
            
        },
    },
    watch: {},
    components: {
        LiefengContent,
        LiefengTable,
        LiefengModal,
        Install,
    },
}
</script>
    
<style scoped lang='less'>
.readnum {
    display: inline;
    margin-left: 20px;
    font-size: 14px;
    font-weight: 700;
    li {
        display: inline;
        margin-left: 15px;
    }
    .click {
        text-decoration: underline;
        cursor: pointer;
    }
}
.readNum {
    margin-top: 5px;
    font-size: 14px;
    font-weight: 700;
    height: 20px;
    li {
        display: inline;
        margin-left: 15px;
    }
}
</style>